.container {
  margin-top: 16px;
  padding-bottom: 20px;
}

table {
  margin-top: 35px;
  margin-bottom: 15px;
  width: 100%;
  border-spacing: 0 5px;
  border-collapse: unset !important;
}

tbody {
  font-size: small;
}

th,
td {
  padding: 10px;
}

td {
  background-color: rgb(74, 72, 83);
}

tr td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

tr td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pagination {
  justify-content: right;
}