@media screen and (min-width: 661px) {
  .table-control {
    display: flex;
    justify-content: space-between;
  }

  .controls-container {
    justify-content: end;
  }
}

.table-control-section {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 660px) {
  .table-control {
    position: relative;
  }

  .table-control-section,
  .controls-container {
    margin-bottom: 20px;
  }

  .controls {
    display: flex;
    justify-content: space-between;
  }

  .search {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
}