html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #27272B;
}

.App {
  color: rgb(247, 247, 247);
  font-family: "Public Sans" !important;
}

header {
  background-color: #3F4045;
}

.teko {
  font-family: "Teko";
}

.admin-body,
header {
  padding: 0 40px;
}

@media screen and (max-width: 660px) {
  .admin-body,
  header {
    padding: 0 10px;
  }
}

h1 {
  font-size: 1.5rem !important;
  margin: 0;
}

h2 {
  font-size: 2rem !important;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #DDDDDD;
}

a.active {
  background-color: #575960;
}

.btn-primary,
.page-link {
  background-color: #6987C9 !important;
  border: 1px #4165B4 !important;
  color: white;
  border-radius: 0 !important;
  padding: 7px 15px;
  font-size: .85rem;
}

.btn-primary:active,
.btn-primary:hover {
  background-color: #3B5DA5;
  border: 1px #4165B4;
  color: white;
}

.dropdown-menu.show {
  background-color: #6987C9;
}

.dropdown-item {
  color: white;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #3B5DA5;
}

.dropdown-item:focus,
.dropdown-item:hover:not(.active):not(:active) {
  color: black;
}

.pagination {
  font-size: .85rem;
}

.pagination .page-link {
  background-color: #6987C9;
  border-color: #4165B4;
  color: white;
}

.pagination .page-item.active .page-link,
.pagination .page-item:active .page-link {
  background-color: #3B5DA5 !important;
  color: white;
}

.react-bootstrap-table-pagination-list-hidden {
  display: none !important;
}

.customer-group .customer-group-header {
  display: flex;
}

.customer-group .customer-group-header span {
  margin-left: auto
}

.customer {
  padding: .5rem 1rem;
}

.customer input,
.customer input:focus,
.customer select,
.customer select:focus {
  background-color: #303136;
  border-radius: 5px;
  border: none;
  color: white;
}

.customer select {
  text-align: left !important;
}

.table {
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0;
  color: #fff !important;
  background-color: rgb(39, 39, 43);
  border-radius: 0;
}

.table a,
.table .btn {
  color: white;
}

.table-groups .table {
  margin: 0;
}

.table-header {
  border: none;
  text-transform: uppercase;
}

.table th,
.table thead th,
.table td {
  border-bottom: 1px solid #3b3b3b;
}

thead tr {
  color: #96a4b4;
}

.table-row {
  margin: .5rem 0;
}

.table-row-even .table-groups thead,
.table-row-even .btn,
.table-row-even td,
.main-body .page-item.active .page-link {
  color: white;
}

.table-row-odd {
  background-color: white;
  color: black;
}

.table-row-odd .table-groups thead,
.table-row-odd .btn,
.table-row-odd td,
.main-body .page-item.active .page-link {
  color: black;
}

.table-row td {
  background-color: #27272B;
  vertical-align: middle;
}

.table-row.done td {
  background-color: #1d431b;
}

.table-row.running td {
  background-color: #7e00fd;
}

.table-row.next-today td {
  background-color: #1b4c7a;
}

.table-row ul {
  margin: 0;
  padding: 0 0.75rem;
}

.subtable-header {
  display: flex;
  padding: 0 5px;
  justify-content: space-between;
  align-items: center;
  min-height: 35px;
  font-weight: 900;
}

.expanded td {
  opacity: .85;
}

.expanded + tr td {
  background-color: #303136;
}

.row-expansion-style .row > div:last-child {
  text-align: right;
}

.badge {
  font-size: .85rem;
}

.btn-group>.btn-group:not(:first-child), .btn-group>:not(.btn-check:first-child)+.btn {
  margin-left: 0;
}

.form-select:focus {
  box-shadow: none;
}