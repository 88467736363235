.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgb(214, 214, 214);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}

.pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-item:hover {
      background-color: rgb(89, 89, 89);
      cursor: pointer;
    }

.pagination-item.selected {
  background-color: rgb(89, 89, 89);
}

.arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: '';
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgb(214, 214, 214);
  border-top: 0.12em solid rgb(214, 214, 214);
}

.arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.arrow.right {
  transform: rotate(45deg);
}

.pagination-item.disabled {
  pointer-events: none;
}

.pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgb(214, 214, 214);
  border-top: 0.12em solid rgb(214, 214, 214);
}

.pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}
