.dashboard {
  margin-top: 10px;
  font-size: .85rem;
}

.modal-90w {
  max-width: 90% !important;
}

.table-header,
.table-rows {
  font-size: 80%;
}

.table-rows {
  color: black;
}