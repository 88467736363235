.stats {
  height: 100%;
  background-color: #3F4045;
  border-radius: 7px;
}

.stat {
  height: inherit;
}

.stat .title {
  padding: 13px;
  font-weight: bold;
}

.stat .data {
  height: calc(100% - 50px);
  padding: 0 13px 13px 13px;
}

.data :global(canvas) {
  width: 100%;
  margin: 0 auto;
}

.heatmap {
  height: 100%;
  width: 100%;
}