.container {
  display: flex;
  justify-content: right;
  align-items: center;
}

.search {
  position: relative;
  padding: 7px 20px;
  width: 20px;
  font-size: .85rem;
  font-weight: 900;
  letter-spacing: 2px;
  -webkit-appearance: none;
  border-radius: 0;
}

.select {
  border-radius: 0 !important;
  width: 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") !important;
  pointer-events: none;
}

.select:focus {
  box-shadow: none !important;
}

.search,
.select {
  height: 34.4px !important;
  opacity: 0;
  outline: none !important;
  border: none !important;
  background-color: #3F4045;
  transition: width 0.4s ease, opacity 0.4s ease, background-color 0.4s ease;
}

@media screen and (min-width: 661px) {
  .container {
    max-width: 400px;
  }

  .search.active {
    width: 400px;
  }

  .clear {
    position: relative;
    left: -37px;
  }

  .search-box {
    display: contents;
  }
}

.select.active {
  min-width: 85px;
  width: 100px;
  pointer-events: inherit;
}

.search.active,
.select.active {
  opacity: 1;
  background-color: #FFF;
}

@media screen and (max-width: 660px) {
  .search.active {
    width: inherit;
  }

  .clear {
    position: absolute;
    right: 20px;
    margin-top: 10px;
  }

  .search-box {
    position: relative;
    width: 100%;
  }
}