.container {
  margin-top: 16px;
}

.controls {
  display: flex;
  gap: 60px;
}

@media screen and ( max-width: 500px ) { 
  .controls {
    flex-direction: column;
    gap: 40px;
  }
}