#dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 13px 0px;
  cursor: pointer;
}

@media screen and ( max-width: 500px ) { 
  .name {
    display: none;
  }
}