.customers {
  display: flex;
  gap: 7.5px;
  text-align: center;
}

.customer-category {
  border-right: 1px solid #3b3b3b;
  padding-right: 7.5px;
}

.customer-title {
  color: #96a4b4;
}