.dpicker :global(.react-date-picker) {
  padding: 0;
}

.dpicker :global(.react-calendar) {
  border: none;
}

.dpicker :global(.react-date-picker__inputGroup),
.dpicker :global(.react-date-picker__clear-button) {
  display: none !important;
}

.dpicker :global(.react-date-picker__wrapper) {
  border: none;
}

.dpicker :global(.react-date-picker__button) {
  color: white;
  padding: 10.5px;
}

.dpicker :global(.react-date-picker--disabled) {
  opacity: .65;
}

.dpicker :global(.react-calendar__month-view__weekdays) {
  color: black;
}

.tile {
  position: relative;
}